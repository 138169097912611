.servicio-tecnico {
    height: 112vh;
    height: auto;
    background-color: transparent;
    width: 100%;
    display: flex;
    justify-content: center;
  
    .servicio-tecnico-contenedor {
      margin: 6vw 0;
      background-color: transparent;
      height: 100%;
      width: 84%;
      padding-left: 5em;
    }
}

.servicio-tecnico-header {
    display:flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0em;
    background-color: transparent;
    padding: 2em;
    height: 8em;

    h1 {
      margin-top: 1em;
      font-size: 3.2em;
      color: #0A5AA3;
    }

    .puntos {
      display:flex;
      justify-content: end;
      img {
        width: 50%;
      }
    }
  }
  
  .servicio-tecnico-content {
    padding: 0;
    display: flex;
    align-content: space-between;

    .carousel { 
      flex: 0 0 40%;

      .tarjeta {
        width: 450px;
        height: 485px;
        margin: 0 2em;
        z-index: -1;

        img {
          max-width: 100%;
          height: auto;
        }

        h3 {
          color: white;
          padding: 0.7em 1.3em;
          margin: 0;
          background: linear-gradient(to left, #0082F5, #00CBF7);
        } 
      }
    }  
  
    .carousel-control-next {
      right: 2vw; /* Ajusta esto según tus necesidades */
      top: 50%; /* Ajusta esto según tus necesidades */
      transform: translateY(-50%);
    }

    .carousel-indicators {
      position: absolute;
      top: 77.5%;
      right: 100px;
    }
  
    .parrafo {
      flex: 0 0 60%;
      margin-top: 2em;
      text-align: left;
      display:flex;
      flex-direction: column;
  
       .linea-servicio-tec {
        margin-top: auto;
        margin-left: 4em;
        margin-bottom: 1em;
        /*
        animation: fadeInRight;
        animation-duration: 3s;
        */
       }

       .animate__fadeInRight {
        animation: fadeInRight;
        animation-duration: 1s;
       }

       .not__Hovered {
        display: none;
       }

       p {
        color: rgb(25, 25, 25);
        font-size: 25px;
        padding: 0 5em;
        margin-top: 2em;
  
        span {
          color: #0082F5;
        }
      }
    }
}