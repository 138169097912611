.hero3 {
    margin-top: 3.75rem;
    width: 100%;
    height: 78vh;
    /*background-image: url('../img/rsz_rsz_guardia-radio-derecha-espacio.png');*/
    background-image: url('../img/rsz_rsz_guardia-radio-derecha-espacio.webp');
    background-size: cover;
    background-position: top right 13%;
    box-shadow: 0 2px 12px rgba(0, 0, 0, 1);
    position: relative;

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(to right, rgba(0, 0, 0, 0.5), transparent);
        transition: background 0.5s ease;
    }

    &:hover::before {
        background: transparent;
    }

    .hero3-content {
        top: 40%;
        left: 18%;
        transform: translate(-25%, -25%);
        color: white;
        z-index: 1;

        h1 {
            font-size: 4.4rem;
            margin-bottom: 44px;
            width: 100%;
            background: linear-gradient(10deg, #0082F5, #00CBF7, #a6e4f2);
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
        }

        p {
            color: white;
            font-size: 1.35rem;
            width: 100%;
        }
    }

    .marcas-logos3 {
        position: absolute;
        top: 61vh;
        top: 50%;
        left: 1em;
        width: 100%;
        margin-top: 3em;
        padding: 1rem;
        display: flex;
        align-items: center;
        gap: 1.25rem;
        border-radius: 10px;
        transition: transform 1s ease;
        /*
        background-color: rgba(255, 255, 255, 0.932);
        */
        background: transparent;


        img {
            height: auto;
        }

        .movistar-arena {
            /*height: 77%;*/
            width: 13vw;
        }

        .viña {
            /* height: 80%; */
            width: 13vw;
        }

        .lollapalooza {
            /*
            height: 80%;
            */
            width: 13vw;
        }

        .primavera-sound {
            /*
            height: 70%;
            */
            width: 13vw;
            margin-left: 0.5em;
        }
    }
}

/* Innecesario que esté en media query, mover de aquí */
@media (max-width: 768px) {

    .hero3 {
        height: 60vh;

        .hero3-content {
            display: flex;
            justify-content: center;
            flex-direction: column;
            top: 74%;
            margin-left: 3rem;
            width: 90%;
            h1 {
                margin-bottom: 0.5rem;
                font-size: 27px;
            }
            p {
                font-size: 13px;
            }
        }

        .marcas-logos3 {
            top: 45.5vh;
            height: 12%;
            width: 90%;
        }
    }
}

@media (max-width: 346px) {

    .hero3 {

        .hero3-content {
            margin-left: 2rem;
        }

        .marcas-logos3 {
            height: 11%;
            width: 80%;
        }
    }
    
}