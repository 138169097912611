.why2 {
    position: relative;
    z-index: 900;
    overflow: hidden;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
    width: 100%;
    height: 82vh;
    color: black;
    display: flex;
    justify-content: center;
    flex-direction: column;
    ul {
      list-style: none;
    }
    background-color: transparent;
}

.why2 {
    height: auto;
  
    .why2-contenedor {
      background-color: transparent;
      height: auto;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      margin: 0;

      .carousel2 {
        width: 100%;
        max-height: 100%;
        .carousel-control-prev-icon,
        .carousel-control-next-icon {
          background-color: transparent;
          width: 100px;
          height: 100px;
        }
  
        .carousel-control-prev,
        .carousel-control-next {
          top: 75%;
          transform: translateY(-50%);
          width: 10%;
          display: none;
        }
  
        .carousel-item {
          ul {
            padding: 0;
            margin: 0;
          }
        }
  
        .carousel-indicators li{
          background-color: #000;
          width: 25px;
          height: 25px;
          border-radius: 50%;
        }
  
        .li-imagenes{
          margin-top: 0.5em;
          margin-bottom: 0.25em;
        }
  
        .carousel-indicators {
          margin-top: 0.75em;
          top: 100%;
        }
      }
    }
}
  
.why2-contenedor .carousel2 {
    margin: 0em;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: white;
    background-color: #f1f1f1;
  
    h3 {
        font-size: 1.6em;
        font-weight: bold;
        color: #0A5AA3;
        color: #0a4754;
    }

    p {
        font-size: 1.2rem;
        color: black;
    }

    .why2-header {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 6em;
        margin-bottom: 1em;
        background: linear-gradient(to right, #12829b, #343434);

        h1 {
            margin:  0;
            color: white;
        }
    }

    .why2-info {
        margin: 2em 0;
        padding: 0 1em;
    }

    .why2-textos-informacion {

        .contenedor-boton-mas-informacion {
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 1em;
            margin-bottom: 1.5em;
        }

        .boton-mas-informacion {
            width: 43%;
            color:#fff;
            margin-left: 1em;
            padding: 1em;
            background-color: #0A5AA3;
            background: linear-gradient(to right,  #12829b, #343434);
            border-radius: 5px;
            font-size: 20px;
        }

        .boton-mas-informacion:hover {
            background: linear-gradient(to left,  #12829b, #343434);
            cursor: pointer;
        }

        
    }
}

.why2-footer {
    width: 100%;
    padding: 1em;
    background: linear-gradient(to left,  #12829b, #343434);
    h3 {
      color: white !important;
      font-size: 1.2em;
    }
    p {
      color: white !important;
      font-size: 0.5rem;
    }
}

.pdf-iframe {
    width: 100%;
    height: 110vw;
  }
  
.modal-footer-left {
    display: flex;
    justify-content: flex-start;
}

