
.footer2 {
    background-color: #3c3c43;
    position: absolute;
    width: 100%;
    padding: 1em 2em;
    color: #CDD6E5;
  
    .bymas-motorola {
      padding-bottom: 1em;
      display: flex;
      justify-content: space-between;
      align-items: center;
  
      .div-puntitos-1 {
        display: flex;
        flex-direction: column;
        align-items: start;
        gap: 1em;
      }
  
      .div-puntitos-2 {
        align-items: end;
        display: flex;
        flex-direction: column;
        gap: 1em;
      }
    }
  
    .contenedor-contactanos {
      margin: 2em 0;
      display: inline-block;
      h1 {
        border: 0;
        font-size: 44px;
        font-weight: bold;
        color: #fff;
        text-shadow: 
          -1.5px -1.5px 0 #0A5AA3,  
          1.5px -1.5px 0 #0A5AA3,
          -1.5px  1.5px 0 #0A5AA3,
          1.5px  1.5px 0 #0A5AA3;
      }
    }
    
    .bymas-logo {
      padding: 1.5em 0;
      animation: rubberBand; /* referring directly to the animation's @keyframe declaration */
      animation-duration: 3s;
    }
  
    .footer2-columnas {
      padding: 0;
      padding-bottom: 2rem;
      width: 95%;
      display: flex;
      flex-direction: row;
      align-items: start;
      gap: 2em;
      font-size: 16.5px;
  
      .footer2-columna {
        h3 {
          color: white;
          margin-bottom: 1em;
        }
        ul {
          padding: 0;
          li {
            list-style:none;
            a {
              text-decoration: none;
              color: #CDD6E5;
            }
          }
        }
      }
  
      .c1 {
        
        .footer2-logo {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
          .contorno {
            font-size: 14px;
            background-color: transparent;
            border-radius: 15px;
            padding: 1px 10px;
            display: inline-block;
          }
          h3 {
            color: #CDD6E5;
            font-size: 22px;
          }
        }
      }
  
      .c1 {
        color: white;
        display: flex;
        flex-direction: column;
        flex: 1;
        text-align: start;
        margin-top: 0.7em;
      }
  
      .c2 {
        margin-top: 2rem;
        text-align: start;
        flex: 1.1;
        ul {
          padding: 0;
  
          li {
            margin-top: 0.5em;
          }
        }
        span {
          text-decoration: none;
          color: #CDD6E5;
          font-size: 1rem;
        }
        span:hover {
          cursor: pointer;
          color: #00CBF7;
        }
      }
  
      .c3 {
        flex: 1.1;
        margin-top: 2rem;
        text-align: start;
  
        .texto-contactanos2 {
          width: 60%;
        }
      }
  
      .c4 {
        display: none;
        flex: 1.75;
        text-align: start;
      }
      
      .c5 {
        margin-top: 5rem;
        display: flex;
        width: 100%;
        height: 100%;
        flex: 1;
        align-items: center;
        justify-content: center;
        /*
        img {
            
          margin-top: 2em;
          margin-left: 5em;
          
        }
          */
      }
  
      .aplicar-movimiento {
        animation: rubberBand;
        animation-duration: 2s;
      }
  
    }
  
    input {
      width: 55%;
      padding: 0.25em 1em;
      border-radius: 50px;
    }
    .via-email2 {
      margin-left: 0.2em;
    }
    button {
      padding: 0.25em 1.5em;
      margin-left: 1.5em;
      border-radius: 50px;
      /*
      background-color: #0093D2;
      background-color: gray;
      */
      background: linear-gradient(to right, #0A5AA3, #0082F5, #00CBF7);
      background: linear-gradient(to right, #1cbce0, #343434);
      color: #CDD6E5;
      color: black;
      color: white;
    }
  
    button:hover {
      
      background: linear-gradient(to left,#0A5AA3, #0082F5, #00CBF7);
      background: linear-gradient(to left,#1cbce0, #343434);
    }
  
    .linea-puntos {
      margin-top: 5em;
      display: flex;
      gap: 1em;
    }
  
    .enviar-correo2 {
      margin-top: 1em;
      height: 3em;
      display: flex;
      align-items: center;
      width: 100%;
      justify-content: start;
      font-size: 18px;
      gap: 0;
  
      p {
        font-size: 20px;
        color: white;
      }
  
      .via-email2 {
        margin: 0;
        padding: 0;
        width: 33%;
      }
      input {
        margin-left: 0;
        width: 40%;
      }
    }
  
    .derechos2 {
      height: 1.5em;
      margin: 0;
    }
  
  }