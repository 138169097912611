


.footer3 {
  background-color: #3c3c43;
  position: absolute;
  width: 100%;
  padding: 0em;
  color: #CDD6E5;
  overflow: hidden;

  .bymas-motorola {
    padding-bottom: 1em;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .div-puntitos-1 {
      display: flex;
      flex-direction: column;
      align-items: start;
      gap: 1em;
    }

    .div-puntitos-2 {
      align-items: end;
      display: flex;
      flex-direction: column;
      gap: 1em;
    }
  }

  .contenedor-contactanos {
    margin: 2em 0;
    display: inline-block;
    h1 {
      border: 0;
      font-size: 44px;
      font-weight: bold;
      color: #fff;
      text-shadow: 
        -1.5px -1.5px 0 #0A5AA3,  
        1.5px -1.5px 0 #0A5AA3,
        -1.5px  1.5px 0 #0A5AA3,
        1.5px  1.5px 0 #0A5AA3;
    }
  }
  
  .bymas-logo {
    padding: 0;
    animation: rubberBand;
    animation-duration: 3s;
  }

  .footer3-columnas {
    padding: 0 2em;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 1em;
    font-size: 15px;

    .footer3-columna {
      h3 {
        color: white;
        margin-bottom: 1em;
      }
      ul {
        padding: 0;
        list-style: none;
      }
    }

    .c1 {
       width: 100%;
       display: flex;
       
      .footer3-logo {
        
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .contorno {
          background-color: transparent;
          border-radius: 15px;
          padding: 1px 10px;
          display: inline-block;
        }

        h3 {
          color: #CDD6E5;
          font-size: 13px;
        }
      }

      .l1 {
        flex: 1;
      }
      .l2 {
        flex: 1.5;
      }
    }

    .c1 {
      order: 3;
      color: white;
      display: flex;
      flex-direction: row;
      justify-content: center;
      margin-top: 0.7em;
    }

    .c2 {
      order: 4;
      text-align: start;
      flex: 1.4;
      display: flex;
      gap: 1rem;
      h3 {
        font-size: 1.1rem;
      }


      #lista-con-punto1, #lista-con-punto2 {
        list-style: none;
      }

      ul {
        padding: 0;
        li {
          margin-top: 0.25em;
        }
      }
      span {
        text-decoration: none;
        color: #CDD6E5;
        font-size: 0.9rem;
      }
      span:hover {
        cursor: pointer;
        color: #00CBF7;
      }
      .subcolumna-c2 {
        width: 50%;
        

        .texto-contactanos {
            width: 100%;
        }
        .sc1 {
            text-align: start;
            
            
        }
        .sc2 {
            text-align: end;
            ul {
                list-style: circle !important;
            }
        }
        
      }
    }

    /*
    .c3 {
      order: 4;
      flex: 1.5;
      text-align: start;

      .texto-contactanos {
        width: 100%;
      }
    }
    */

    .c4 {
      order: 6;
      display: none;
      flex: 1.75;
      text-align: start;
    }
    
    .c5 {
      order: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
      flex: 1;
      margin-top: 1rem;
      /*
      img {
        margin-top: 2em;
        margin-left: 2em;
      }
      */
    }

    .c6 {
        order: 2;
        width: 100%;
        height: 10px;
        color: white;

    }

    .aplicar-movimiento {
      animation: rubberBand;
      animation-duration: 2s;
    }

  }

  input {
    width: 65%;
    padding: 0.5em 1em;
    border-radius: 50px;
  }

  .via-email {
    margin-left: 0.2em;
  }

  button {
    padding: 0.5em 1.5em;
    margin-left: 1.5em;
    border-radius: 50px;
    font-size: 12px;
    /*
    background-color: #0093D2;
    background-color: gray;
    */
    background: linear-gradient(to right, #0A5AA3, #0082F5, #00CBF7);
    background: linear-gradient(to right, #1cbce0, #343434);
    color: #CDD6E5;
    color: black;
    color: white;
  }

  button:hover {
    background: linear-gradient(to left,#0A5AA3, #0082F5, #00CBF7);
    background: linear-gradient(to left,#1cbce0, #343434);
  }

  .linea-puntos {
    margin-top: 5em;
    display: flex;
    gap: 1em;
  }

  .enviar-correo3 {
    margin: 1em 0;
    margin-bottom: 2em;
    height: 4em;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    justify-content: start;
    font-size: 18px;
    gap: 0.5rem;

    .enviar-correo-cajita1, .enviar-correo-cajita2 {
        width: 100%;
    }

    h3 {
      
      font-size: 1rem;
      color: white;
      img {
        margin-right: 0.5rem;
      }
    }

    .via-email {
      margin: 0;
      padding: 0;
      width: 100%;
    }

    input {
      font-size: 10px;
      margin-left: 0;
      width: 55%;
      padding: 0.35rem;
      text-align: center;
    }
  }

  .derechos3 {
    height: 2.75em;
    margin: 0;
    font-size: 12px;
  }

}

a {
  text-decoration: none;
  color: #CDD6E5;
}