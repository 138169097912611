.nav {
  position: fixed;
  max-width: 100%;
  width: 100vw;
  padding: 0 2em;
  padding-left: 4em;
  background: linear-gradient(to right,#0A5AA3, #0082F5, #00CBF7, #baf2fe, white);
  height: 4.75em;
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.4);
  color: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 1000;
  overflow: hidden;
}

.nav-links {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 2em;

  span, .nav-sublink a {
    text-decoration: none;
    color: inherit;
    font-weight: bold;
    font-size: 1rem;
  }

  span:hover, .nav-sublink:hover {
    cursor: pointer;
    color: rgb(168, 241, 255);
  }
}

.nav2 {
  background: linear-gradient(to left,#0A5AA3, #0082F5, #00CBF7, #baf2fe, white);
  padding: 0 2em;
  padding-right: 4em;
}