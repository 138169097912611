.nav2 {
    position: fixed;
    max-width: 100%;
    width: 100%;
    padding-left: 2rem;
    background: linear-gradient(to right,#0A5AA3, #0082F5, #00CBF7);
    height: 4.75em;
    box-shadow: 0 2px 12px rgba(0, 0, 0, 0.4);
    color: white;
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 1000;
    overflow: hidden;
}

.nav2-links {
    width: 90%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    span, .nav2-sublink a {
        text-decoration: none;
        color: inherit;
        font-weight: bold;
        font-size: 0.8rem;
    }

    span:hover, .nav2-sublink:hover {
        cursor: pointer;
        color: rgb(168, 241, 255);
    }
}

a {
    text-decoration: none !important;
    color: white !important;
}