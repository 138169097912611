/*

.footer {
    position: absolute;
    width: 100%;
    padding: 2em;
    padding-bottom: 2em;
    background: linear-gradient(to left,#0A5AA3, #0082F5, #00CBF7);
    color: white;

    .bymas-motorola {
      padding: 0 2em;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .div-puntitos {
        display: flex;
        gap: 1em;
      }
    }

    .footer-line {
      height: 1px;
      background-color: white;
    }

    .contenedor-contactanos {
      margin: 2em 0;
      display: inline-block;
      h1 {
        border: 0;
        font-size: 44px;
        font-weight: bold;
        color: #fff;
        text-shadow: 
          -1.5px -1.5px 0 #0A5AA3,  
          1.5px -1.5px 0 #0A5AA3,
          -1.5px  1.5px 0 #0A5AA3,
          1.5px  1.5px 0 #0A5AA3;
      }
    }
    
    .bymas-logo {
      padding: 1.5em 0;
      animation: rubberBand;
      animation-duration: 3s;
    }

    .footer-logos {
      display: flex;
      flex-direction: row;
      justify-content: center;
      gap: 4em;
      .footer-logo {
        .contorno {
          background-color: #f0f0f0;
          border-radius: 15px;
          padding: 1px 10px;
          display: inline-block;
        }
        h3 {
          color: #0A5AA3;
          font-size: 22px;
        }
      }
    }

    .linea-puntos {
      margin-top: 5em;
      display: flex;
      gap: 1em;
    }
}

.derechos {
    margin-top: 2em;
}

*/

.footer {
  background-color: #3c3c43;
  position: absolute;
  width: 100%;
  padding: 1em 2em;
  color: #CDD6E5;

  .bymas-motorola {
    padding-bottom: 1em;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .div-puntitos-1 {
      display: flex;
      flex-direction: column;
      align-items: start;
      gap: 1em;
    }

    .div-puntitos-2 {
      align-items: end;
      display: flex;
      flex-direction: column;
      gap: 1em;
    }
  }

  .contenedor-contactanos {
    margin: 2em 0;
    display: inline-block;
    h1 {
      border: 0;
      font-size: 44px;
      font-weight: bold;
      color: #fff;
      text-shadow: 
        -1.5px -1.5px 0 #0A5AA3,  
        1.5px -1.5px 0 #0A5AA3,
        -1.5px  1.5px 0 #0A5AA3,
        1.5px  1.5px 0 #0A5AA3;
    }
  }
  
  .bymas-logo {
    padding: 1.5em 0;
    animation: rubberBand; /* referring directly to the animation's @keyframe declaration */
    animation-duration: 3s;
  }

  .footer-columnas {
    padding: 2em;
    width: 95%;
    display: flex;
    flex-direction: row;
    align-items: start;
    gap: 1em;
    font-size: 16.5px;

    .footer-columna {
      h3 {
        color: white;
        margin-bottom: 1em;
      }
      ul {
        padding: 0;
        li {
          list-style:none;
          a {
            color: #CDD6E5;
            text-decoration: none;
          }
        }
      }
    }

    .c1 {
      .footer-logo {
        .contorno {
          background-color: transparent;
          border-radius: 15px;
          padding: 1px 10px;
          display: inline-block;
        }
        h3 {
          color: #CDD6E5;
          font-size: 22px;
        }
      }
    }

    .c1 {
      color: white;
      display: flex;
      flex-direction: column;
      justify-content: center;
      flex: 1.5;
      text-align: start;
      margin-top: 0.7em;
    }

    .c2 {
      text-align: start;
      flex: 1.4;
      ul {
        padding: 0;

        li {
          margin-top: 0.5em;
        }
      }
      span {
        text-decoration: none;
        color: #CDD6E5;
        font-size: 1rem;
      }
      span:hover {
        cursor: pointer;
        color: #00CBF7;
      }
    }

    .c3 {
      flex: 1.5;
      text-align: start;

      .texto-contactanos {
        width: 60%;
      }
    }

    .c4 {
      display: none;
      flex: 1.75;
      text-align: start;
    }
    
    .c5 {
      display: flex;
      width: 100%;
      height: 100%;
      flex: 1;
      img {
        margin-top: 2em;
        margin-left: 5em;
      }
    }

    .aplicar-movimiento {
      animation: rubberBand;
      animation-duration: 2s;
    }

  }

  input {
    width: 55%;
    padding: 0.25em 1em;
    border-radius: 50px;
  }
  .via-email {
    margin-left: 0.2em;
  }
  button {
    padding: 0.25em 1.5em;
    margin-left: 1.5em;
    border-radius: 50px;
    /*
    background-color: #0093D2;
    background-color: gray;
    */
    background: linear-gradient(to right, #0A5AA3, #0082F5, #00CBF7);
    background: linear-gradient(to right, #1cbce0, #343434);
    color: #CDD6E5;
    color: black;
    color: white;
  }

  button:hover {
    
    background: linear-gradient(to left,#0A5AA3, #0082F5, #00CBF7);
    background: linear-gradient(to left,#1cbce0, #343434);
  }

  .linea-puntos {
    margin-top: 5em;
    display: flex;
    gap: 1em;
  }

  .enviar-correo {
    margin-top: 1em;
    height: 3em;
    display: flex;
    align-items: center;
    width: 90%;
    justify-content: start;
    font-size: 18px;
    gap: 0;

    p {
      font-size: 20px;
      color: white;
    }

    .via-email {
      margin: 0;
      padding: 0;
      width: 33%;
    }
    input {
      margin-left: 0;
      width: 30%;
    }
  }

  .derechos {
    height: 1.5em;
    margin: 0;
  }

}