.boton-modelo {
    color:#fff;
    padding: 0.1em 0.6em;
    background-color: #00A0DD;
    /*
    background: linear-gradient(to left,  #29d8ff, #019edd);
    background: linear-gradient(to left,  #007089, #003348);
    */
    background-color: #008ec6;
    border-radius: 5px;
    border: 1px solid #00CBF7;
    border: none;
    font-size: 18px;
}

.boton-modelo:hover {
    background: linear-gradient(to right,  #007089, #003348);
    cursor: pointer;
}

.boton-detalles {
    color:#fff;
    padding: 0.6em 1.2em;
    background-color: #008ec6;
    border-radius: 5px;
    border: none;
}

.boton-detalles:hover {
    background: linear-gradient(to right,  #007089, #003348);
    cursor: pointer;
}

.contenedor-ficha-tecnica {

    .h3-hoja {
        font-size: 4.5vw;
        color: #0A5AA3;
    }

    .seleccionador-modelo {
        font-family: "Radio Canada Big", sans-serif;
        padding: 0.6em 0;
        background: linear-gradient(to right,#0A5AA3, #0082F5, #00CBF7);
        color: white;
        padding-left: 1em;
        font-size: 20px;
        span {
            margin-left: 2em;
            margin-right: 1em;
        }
        .boton-modelo {
            margin-right: 1em;
        }
        
    }
}

.ficha-tecnica3 {

    .linea-separacion1 {
        background-color: #0A5AA3;
        height: 1px;
        margin-bottom: 1em;
        margin-top: 0;
    }

    width: 100%;
    display: flex;
    padding: 0 0.75em 1em 0.5em;

    h1, h2 {
        text-align: center;
    }

    h1 {
        color: #0A5AA3;
        font-size: 8vw;
    }

    h2 {
        font-size: 4.5vw;
        color: #00A0DD;
    }

    .linea-separacion2 {
        background-color: #0A5AA3;
        height: 2px;
        margin-top: 1em;
        margin-bottom: 2em;
    }

    h4 {
        font-size: 4.25vw;
    }

    .c-izquierda {
        flex: 1.5;
    }

    .c-derecha {
        flex: 1;
        

        .titulos {
            width: 100%;
            display: flex;
            h1, h2 {
                text-align: start;
            }

            .img-radio {
                margin: 0 2em 0 1em;
            }

            .img-radiodep {
                margin: 0 2em 0 1em;
            }
            

            .titulos-2 {
                margin: 0;
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                align-content: center;

                h1 {
                    font-size: 7.5vw;
                }
            }


        }

        .contenedor-textos {
            display: flex;
            flex-direction: column;
            gap: 1em;
    
            color: rgb(70, 70, 70);
    
            h3 {
                font-size: 4.5vw;
                color: #00A0DD;
                margin-bottom: 1.5em;
            }
    
            p {
                font-size: 3.75vw;
                margin: 1vw 0 4vw 0; /* Ajusta el margen superior e inferior a 5 píxeles */
            }

            .texto-izquierda {
                flex: 1;
            }
    
            .texto-derecha {
                flex: 1;
                
                span {
                    font-style: italic;
                }
                .accesorios {
                    margin-top: 1.5em;
                }
                
            }
            
        }
        .caja-boton-detalles {
            display: flex;
            justify-content: end;
            align-items: end;
            margin-top: 1em;
        }
    }  
}

.texto-en-celeste3 {
    display: flex;
    flex-direction: column;
    background-color: #00A0DD;
    
    .caja-logo {
        display: flex;
        justify-content: center;
        align-items: center;
        flex: 2;
    }

    .caja-texto {
        /* 
            float: right;
            text-align: start;
            width: 100%;
            
            padding: 2em;
            padding-left: 37em;
            padding-right: 10em;
        */
        
        font-family: "Arimo", sans-serif;
        font-optical-sizing: auto;
        font-style: normal;
        padding: 1.5em;
        color: white;
        flex: 3.1;
        font-size: 3.5vw;
    }
}

.component {
    display: none;
    opacity: 0;
    transition: opacity 0.5s;
}

.component.active {
    display: block;
    opacity: 1;
}

.texto-introduccion3 {
    margin-bottom: 1em;
    width: 100%;
}

.texto-en-gris3 {
    background-color: gray;
    color: white;    
    font-size: 4vw;
    padding-top: 0.4em;

    font-family: "Inconsolata", monospace;
    font-optical-sizing: auto;
    font-weight: normal;
    font-style: normal;
    font-variation-settings: "wdth" 100;
    font-family: "Noto Sans JP", sans-serif;
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal;

    .caja-texto-dep {
        padding: 0.5em 1em;
    }
}

.slider {
    width: 100%;
    display: flex;
    transition: transform 0.5s ease-in-out;
}

.next {
    transform: translateX(0%);
}

.prev {
    transform: translateX(-100%);
}

.navigation {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 20px;
}

.arrow-button3 {
    z-index: 800;
    position: fixed;
    top: 55vh;
    background: none;
    border: none;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 10px;
    font-weight: bold;
}

.arrow-left3 {
    left: 0.25em;
}

.arrow-right3 {
    right: -0.2em;
}

.arrow-button3:hover {
    color: black;
}

.arrow-button3 .icon {
    width: 0px;
    height: 30px;
    transition: color 0.3s ease; /* Transición suave para el cambio de color */
}

.arrow-button3:hover .icon {
    color: blue;
}

.info-foto3 {
    width: 100%;
    text-align: center;
}

.active-button {
    background: linear-gradient(to right,  #007089, #003348);
    cursor: pointer;
}